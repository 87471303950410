.Reasons{
    padding-left: 2rem;
    display: flex;
    gap: 2rem;
}
.left-r{
    flex: 1 1;
    display: grid;
    grid-template-columns: repeat(3,1fr);
    gap: 1rem;
    grid-auto-rows: 1fr;
}.left-r>img{
    object-fit: cover;
}
.left-r>:nth-child(1){
    width: 14rem;
    grid-row: 1/3;
    height: 28.3rem;
}
.left-r>:nth-child(2){
    width: auto;
    
    height: 16rem;
    grid-column: 2/4;
}
.left-r>:nth-child(3){
    width: 14rem;
    grid-column: 2/3;
    grid-row: 2rem;
    
}
.left-r>:nth-child(4){
    width: 10rem;
    grid-row: 2;
    grid-column: 3/4;
    height: 11.3rem;
    
}

.right-r{
    flex: 1 1;
    text-transform: uppercase;
    gap: 1rem;
    display: flex;
    flex-direction:column ;
}
.right-r>span{
    font-weight: bold;
    color: var(--orange);
}
.right-r>div{
    color: white;
    font-size: 3rem;
    font-weight: bold;
}
.details-r{
    display: flex;
    flex-direction: column;
    gap: 1rem;
    
}
.details-r>div{
    display: flex;
    font-size: 1rem;
    gap: 1rem;

}
.details-r>div>span{
    padding-top: 0.4rem;
}
.details-r>div>img{
    
    width: 2rem;
    height: 2rem;
}
.partners{
    display: flex;
    gap: 1rem;
    height: 2rem;
    padding-right: 20rem;
    width: 2rem;
    padding-top: 24rem;
}
.partners>img{
    width: 2.5rem;
}
@media screen and (max-width:768px) {
    .Reasons{
        flex-direction: column;
        
    }
    .right-r{
        
        justify-content: center;
        
    }
    .right-r>div{
        display: flex;
        flex-direction: column;
    
    }
    .partners{
        padding-top: 0rem;
    }
    .details-r{
        padding: 0rem 1rem;
        
    }
    .left-r{
        grid-auto-rows: auto;
        overflow: hidden;
        gap: 5px;
    }
    .left-r>:nth-child(1){
        width: 7rem;
        height: 17rem;
    }
    .left-r>:nth-child(2){
        width: 9rem;
        height: 10.3rem;
    }
    .left-r>:nth-child(3){
        width: 4rem;
        height: 6rem;
    }
    .left-r>:nth-child(4){
        width: 4.5rem;
        height: 6rem;
        margin-left: -16px;
    }
    
    
}