.join{
    display: flex;
    padding: 1rem 2rem;
    gap: 1rem;
    margin-top: -59rem;
}
.left-j{
    color: white;
    font-size: 3rem;
    font-weight: bold;
    text-transform: uppercase;
    position: relative;
    gap: 3px;
}
.level-up{
    padding: 3rem;
}
.joinwithus{
    padding:3rem
}
.left-j>hr{
    
    border: solid 2px var(--orange);
    position: absolute;
    width: 10.5rem;
    bottom: 6rem;
    border-radius: 20%;
}
.right-j{
    display: flex;
    justify-content: center;
    align-items: flex-end;
}
.email-container{
    display: flex;
    gap: 3rem;
    padding: 1rem 3rem;
    background-color: gray;
   
}
.btn-j{
    color: white;
    background-color: var(--orange);
}
.btn-j:hover{
    cursor: pointer;
    transform: scale(1.2);
    transition: 0.3s;
    border-radius: 10%;
}
.email-container>input{
    background-color: transparent;
    border: none;
    outline: none;
    color: var(--lightgray);
}
::placeholder{
    color: var(--lightgray);
}
@media screen and (max-width:769px)  {
    .join{
        display: flex;
        padding: 1rem 2rem;
        gap: 1rem;
        margin-top: -38rem;
        flex-direction: column;
    }
    .left-j{
        color: white;
        font-size: 3rem;
        font-weight: bold;
        letter-spacing: 0;
        text-transform: uppercase;
        position: relative;
        align-self: center;
        flex-direction: column;
        gap: 3px;
        
    }
    .left-j>hr{
        
        border: solid 2px var(--orange);
        position: absolute;
        width: 9rem;
        bottom: 6rem;
        border-radius: 20%;
    }
    .level-up{
        
        padding:1rem;
    }
    
    .joinwithus{
        text-align: center;
        display: inline ;
        font-size: 2.2rem
    }
    .email-container{
        display: flex;
        gap: 0rem;
        padding: 1rem 0.8rem;
        background-color: gray;
        box-shadow: 2px 3px 17px;
       
    }
    .btn-j{
        width: 4rem;
        height: 3rem;
        color: white;
        background-color: var(--orange);
    }
    .right-j{
        display: flex;
        padding: 1rem 1rem;
        justify-content: center;
        align-items: flex-end;
    }

    
}