.testimonials{
    display: flex;
    gap: 1rem;
    padding: 2rem 2rem;
    
}
.left-t{
    display: flex;
    flex-direction: column;
    flex: 1 1;
    text-transform: uppercase;
    color: white;
    padding-bottom: 3rem;
}
.left-t>:nth-child(1){
    color: var(--orange);
    font-weight: bold;
    padding-bottom: 1rem;
}
.left-t>:nth-child(2),.left-t>:nth-child(3){
    font-weight:bold;
    font-size: 3rem;
    padding-bottom: 2rem;
}
.left-t>:nth-child(4){
    text-transform: none;
    text-align: justify;
    letter-spacing: 2px;
    width: 650px;
    padding-bottom: 2rem;
    line-height: inherit;
    position: relative;
}
.right-t{
    
    flex:1;
    position: relative;
    
}
.right-t>img{
    width: 17rem;
    height: 20rem;
    object-fit: cover;
    right: -59rem;
    top: -60rem;
    position: relative;
}
.right-t>:nth-child(1){
    position: relative;
    width: 17rem;
    height: 20rem;
    border: 2px solid var(--orange);
    background-color: transparant;
    right: -58rem;
    top: -22.1rem;
}
.right-t>:nth-child(2){
    position: relative;
    width: 17rem;height: 19rem;
    top: -39rem;
    right: -60rem;
    background: var(--planCard) ;
}
.arrows{
    display: flex;
    position: relative;
    width: 1rem;
    height: 1rem;
    gap: 1rem;
    left: 6rem;
    top: -58rem;
}
.arrows>img:hover{
    cursor: pointer;
    opacity: 0.4;  
}
@media screen and (max-width:768px){
    .testimonials{
        flex-direction: column;
    }
    .right-t{
     align-self: center;
     align-items: center;
    }
    .left-t{
        align-items: center;
        align-self: center;
    }
    .left-t>:nth-child(4){
        text-transform: none;
        text-align: justify;
        letter-spacing: 1px;
        width: 250px;
        padding-bottom: 2rem;
        line-height: inherit;
        position: relative;
    }
    .left-t>:nth-child(2){
        font-weight:bold;
        font-size: 3rem;
        padding-bottom: 0.5rem;
    }
    .right-t>img{
        width: 17rem;
        height: 20rem;
        object-fit: cover;
        right: -1rem;
        top: -35rem;
        position: relative;
    }
    .right-t>:nth-child(1){
        position: relative;
        width: 17rem;
        height: 20rem;
        border: 2px solid var(--orange);
        background-color: transparant;
        right: -2rem;
        top: 3rem;
    }
    .right-t>:nth-child(2){
        position: relative;
        width: 17rem;height: 19rem;
        top: -14.4rem;
        right: -0.4rem;
        background: var(--planCard) ;
    }
    .arrows{
        display: flex;
        position: relative;
        width: 1rem;
        height: 1rem;
        gap: 1rem;
        left: 7.9rem;
        top: -59.3rem;
    }
    
}