.footer-container{
    
    position: relative;
}
.footer-container>hr{
    border: 1px solid var(--lightgray);
}

.footer{
    padding: 3rem 2rem;
    display: flex;
    flex-direction: column;
    gap: 4rem;
    align-items: center;
    justify-content: center;
    height: 20rem;
}
.social-icons{
    display: flex;
    
    gap: 4rem;
    
}
.social-icons>img{
    width: 2rem;
    height: 2rem;
    cursor: pointer;
}
.logo>img{
    position: relative;
    display: flex;
    width: 12rem;
    right: 1rem;
    
}
.logo>img:hover{  
    transform: scale(1.01);
}
.blr-footer1{
    bottom: 0;
    right: 15%;
    width: 23rem;
    height: 12rem;
    filter:blur(200px);
    background: red;
}
.blr-footer2{
   
    bottom: 0;
    left: 15%;
    width: 36rem;
    height: 12rem;
    filter:blur(200px);
    background: rgb(238, 165, 7);
}


