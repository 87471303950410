.programs{
    display: flex;
    flex-direction: column;
    gap: 2rem;
    padding:0 2rem;
    padding-left: 2rem;

}
.programs-header{
    padding-top: 2rem;
    display: flex;
    gap: 5rem;
    font-weight: bold;
    font-size: 3rem;
    justify-content: center;
    color: white;
    text-transform: uppercase;
    font-style: italic;
}
.program-categories{
    padding-top: 4rem;
    display: flex;
    gap: 1rem;
    padding-left: 2rem;
    padding-right: 2rem;

    
}
.category{
    display: flex;
    flex-direction: column;
    background-color: gray;
    text-align: justify;
    padding: 2rem;
    gap: 1rem;
    color: white;
    justify-content: space-between;
    width: 17rem;   
}
.category>:nth-child(1){
    width: 2rem;
    height: 2rem;fill: white;
}
.category>:nth-child(2){
    font-size: 1.2rem;
    font-weight: bold;
}
.category>:nth-child(3){
    font-size: 0.9rem;
    line-height: 25px;
}
.join-now{
    display: flex;
    align-items: center;
    gap: 2rem;
}
.join-now>img{
    width: 1.3rem;
}
.join-now>img:hover{
    transform: scale(1.5);
    
    
}
.category:hover{
    background: var(--planCard);
    cursor: pointer;
}
@media screen  and (max-width:768px) {
    .programs-header{
        flex-direction: column;
        gap: 1rem;
        font-size: x-large;
        align-items: center;
        justify-content: center;
        margin-top: 2rem;
    }
    .program-categories{
        display: flex;
        flex-direction: column;
        align-items: center;
        
    
    }
    .category{
        display: flex;
        flex-direction: column;
        background-color: gray;
        text-align: justify;
        padding: 2rem;
        gap: 1rem;
        color: white;
        justify-content: center;
        padding: 1rem 2rem;
        width: 13rem;   
    }
    
}